import _objectSpread from "/var/lib/jenkins/workspace/v4-frontend-forum-backend@2/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapMutations, mapActions } from 'vuex';
export default {
  name: 'App',
  data: function data() {
    return {
      isShowResponseModal: false
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState('system', {
    siteInfo: function siteInfo(state) {
      return state.siteInfo;
    }
  })), mapState('user', {
    roles: function roles(state) {
      return state.roles;
    }
  })),
  watch: {
    roles: {
      handler: function handler(value) {
        var vm = this;
        if (value && value.length) {
          vm.getHk6Config();
        }
      },
      immediate: true
    }
  },
  created: function created() {
    var vm = this;
    vm.$root.$on('showResponseModal', function () {
      vm.isShowResponseModal = true;
      alert('aaa');
    });
  },
  mounted: function mounted() {
    var vm = this;
    vm.SET_SITE_ID(window._jsvar.siteId);
    document.title = vm.siteInfo.headTitle;
    vm.checkFavicon();
  },
  methods: _objectSpread(_objectSpread(_objectSpread({}, mapMutations('system', ['SET_SITE_ID'])), mapActions('forumConfig', ['getHk6Config'])), {}, {
    checkFavicon: function checkFavicon() {
      var vm = this;
      var favicon = document.querySelector('link[type="image/x-icon"]');
      favicon.setAttribute('href', "/site/".concat(vm.siteInfo.sitePrefix, "/favicon.ico"));
    }
  })
};